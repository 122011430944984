@use '@/styles/utils' as *;

.body {
    background: $color-gray-1000;
}

.container {
    --max-width: 1440px;
    --mask-width: 40px;
    --svg: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 1457"><path d="M34.9 1424.9s9.5-11.6 2.7-41.4c1.8-15.5 8.3-5.5 3.3-39.8-1.3-27-.9-39.1-.1-43.7-6.8-1.7-10.9-32.9-7.6-38.8-.4-3.8-6.6-39-3-43.8-1.6-22.4 3-6.3-2.1-42.7.6-45.6-7.5-25.1-5.1-42.3-5.4-32.9-6.1-19.3-7.2-43.1-8.6-47.3-6.2-21.6-4.7-40.1 4 12.8 8.1-16.1-3.6-47-12.4-22.3 5.5-44.9-4.4-56.2-3-5 1.9-50.6 1.8-54.8v-.2.1c0-2 .3-13.3 7.9-34.2 12-8.4-8.5-17.6-2.6-39-2.6-19.8 2.8-7.7 3.8-31.3-6.2-24.9 5.2-2.9 4.5-25.5 5.8-8.1 3.7-53.2 3.3-59.9 1.8-3-.6-28.2-.2-62.3 4 0-4.7-43 1.7-64 11.7-2.1.3-35.5 4.8-40.1-.9 0-1.6-52.3 3.1-40.9 7.8-29.1 4.7-.4-3.2-29.8 1.3-9-3-25.3-4.7-25.7-10.7-32.4 8.1-21-1.5-31.8 2.9-11.6-8.8-43.8-2-30.2-.3-8.8-9.8-.5-6.8-22.8 2.3-11.7-10.7 3.1-6.6-23-9.6-.8-6.5-29.1-2.4-58.6-2.4-23.7 5.5-33 4.9-60 2-14.1 1.2-50.4 8.3-53.7-5.7-50.5 1.4-42.6 4.7-60.8-5.5-5.3 1.8-53.6-1.9-46-8.5-14.4-3.8-17.2-4.3-41.3 0-1.4.1-8.4.2-9.8h53c0 1.4-.3 4.6-.3 6 .5 24.1-4.2 26.9 4.3 41.3 3.7-7.6-3.5 40.7 1.9 46-3.4 18.2-10.4 10.3-4.7 60.8-7 3.2-6.3 39.6-8.3 53.7.5 26.9-7.3 36.3-4.9 60-4.1 29.5-7.2 57.8 2.4 58.6-4.2 26.2 8.8 11.3 6.6 23-3 22.4-1.5 14.9-1.2 23.7-6.8-13.6 4.9 18.6 2 30.2 9.6 10.8-9.1-.6 1.5 31.8 1.8.4 6 16.8 4.7 25.7 7.9 29.4 11.1.7 3.2 29.8-4.7-11.4 3.9 40 4.8 40.1-4.5 4.6 6.9 37.9-4.8 40.1-6.4 21 2.3 64.1-1.7 64-.5 34 2 59.2.2 62.3.4 6.8 2.5 51.8-3.3 59.9.7 22.6-10.6.5-4.4 25.5-1 23.6-6.5 11.5-3.8 31.3-5.9 21.4 14.6 30.6 2.6 39-7.6 20.9-8 32.2-7.9 34.3v.2c.1 4.2-4.8 49.8-1.8 54.8 9.9 11.4-8.1 33.9 4.4 56.2 11.6 30.9 7.6 59.8 3.6 47-1.5 18.5-3.9-7.2 4.7 40.1 1.1 23.8 1.8 10.2 7.2 43.1-2.4 17.2 5.8-3.3 5.1 42.3 5.1 36.3.5 20.3 2.1 42.7-3.6 4.8 2.6 40 3 43.8-6.7 12 3.7 27.7 7.6 38.8 0 0-1.2 16.7.1 43.7 5.1 34.3-1.4 24.3-3.3 39.8 6.8 29.8-2.7 41.4-2.7 41.4s-18.7 24.2-19 36h-53c.3-11.8 19.1-32.2 19.1-32.2Z"/></svg>');

    container-type: inline-size;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    max-width: var(--max-width);
    min-height: 100vh;
    position: relative;

    &::after,
    &::before {
        background-color: $color-gray-1000;
        content: '';
        height: 100%;
        left: 50%;
        mask-image: var(--svg);
        mask-repeat: repeat-y;
        mask-size: 200% auto;
        position: absolute;
        top: 0;
        width: var(--mask-width);
        z-index: 11;

        @container (width < 1440px) {
            display: none;
        }
    }

    &::after {
        mask-position: 0 0;
        transform: translateX(calc(var(--max-width) / 2 - var(--mask-width)));
    }

    &::before {
        mask-position: 100% 0;
        transform: translateX(calc(var(--max-width) / 2 * -1));
    }
}
